import React, { useEffect, useState, useRef } from "react";
import { Image, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "@djthoms/pretty-checkbox";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import BusinessSucessModal from "./BusinessSucessModal";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import { businessUserStoreStart } from "../../store/slices/BusinessSlice";
import { ButtonLoader } from "../Helper/Loader";

const filteredCountries = getCountries().filter(
  (country) =>
    ![
      "AF",
      "BY",
      "CF",
      "CN",
      "CU",
      "CD",
      "ET",
      "IN",
      "IR",
      "IQ",
      "LY",
      "ML",
      "NI",
      "KP",
      "SO",
      "SS",
      "SD",
      "SY",
      "US",
      "VE",
      "YE",
      "ZW",
    ].includes(country)
);

const CreateBusinessInfo = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("business.create");
  const navigate = useNavigate();
  const formRef = useRef();
  const [modalShow, setModalShow] = React.useState(false);
  const businessUserStore = useSelector((state) => state.business.businessUserStore);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
  }),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("title.required")),
    first_name: Yup.string()
      .required(t("first_name.required"))
      .matches(/^\S.*$/, t("first_name.invalid"))
      .trim(),
    middle_name: Yup.string()
      .matches(/^\S.*$/, t("middle_name.invalid"))
      .trim(),
    last_name: Yup.string()
      .required(t("last_name.required"))
      .matches(/^\S.*$/, t("last_name.invalid"))
      .trim(),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.invalid")),
  });

  const title_options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        businessUserStoreStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !businessUserStore.loading &&
      Object.keys(businessUserStore.data).length > 0
    ) {
      setModalShow(businessUserStore.data.sub_user)
    }
    setSkipRender(false);
  }, [businessUserStore]);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="onboarding-auth billing-addres-form business-info-wrapped">
          <div className="payment-table-header">
            <Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h3>{t("header")}</h3>
          </div>
          <Formik
            initialValues={{
              title: "",
              first_name: "",
              middle_name: "",
              last_name: "",
              email: "",
              mobile: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form efi-onboarding-form-after-login w-100 ">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        Title
                      </Form.Label>
                      <Select
                        styles={customStyles}
                        defaultValue={selectedTitle}
                        onChange={(option) => {
                          setSelectedTitle(option);
                          setFieldValue("title", option.value);
                        }}
                        options={title_options}
                        name="title"
                        placeholder={<>Title</>}
                        isSearchable={false}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="title"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        Middle Name
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="middle_name"
                        type="text"
                        placeholder="Middle Name"
                        aria-label="text"
                        aria-describedby="text-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="middle_name"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        Email
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="email-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        First Name
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        aria-label="text"
                        aria-describedby="text-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="first_name"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        Last Name
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        aria-label="text"
                        aria-describedby="text-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="last_name"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        Mobile
                      </Form.Label>
                      <div className="register-phone-input">
                        <PhoneInput
                          name="mobile"
                          defaultCountry={"AE"}
                          placeholder={t("mobile.placeholder")}
                          onChange={(value) => setFieldValue("mobile", value)}
                          onBlur={() => formRef.current.submitForm()}
                          international
                          countries={filteredCountries}
                          className={`${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        component={"div"}
                        name="mobile"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div class="card-details-head-action">
                  <Link className="default-primary-outline w-100" to={-1}>
                    <span>Cancel</span>
                  </Link>
                  <Button
                    type="submit"
                    className="efi-primary-btn w-100 "
                    disabled={businessUserStore.buttonDisable}
                  >
                    {businessUserStore.buttonDisable ? <ButtonLoader/> : "Create"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
        {modalShow ? <BusinessSucessModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        /> : null}
      </div>
    </>
  );
};

export default CreateBusinessInfo;
