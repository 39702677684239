import React from 'react';
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import Select from 'react-select';

const addTeamMember = (props) => {
  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1c1c1c",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1c1c1c!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
  }),
  };

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "price_hl", label: "Price High to Low" },
    { value: "price_lh", label: "Price Low to High" },
  ];

  return (

    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="create-beneficiary-form-heading mb-3">
          <Image
            className="bank-logo-orange"
            src={window.location.origin + "/img/team-member.png"}
            type="image/png"
          />
        </div>
        <h3 className="change-password-heading mb-4">Add New Team Member</h3>
        <div className="efi-bank-default-form">
          <Form className="payament-form-input">
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    Full Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    aria-label="Password"
                    aria-describedby="text-addon"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    aria-label="email"
                    aria-describedby="email-addon"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Phone Number"
                    aria-label="email"
                    aria-describedby="email-addon"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    Roles
                  </Form.Label>
                  <Select
                    options={options}
                    styles={customStyles}
                    isSearchable={false}
                    isClearable
                    placeholder={
                      <div className="placeholder-flex">
                        Select
                      </div>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="payment-form-footer">
          <Button className="efi-cancel-btn" onClick={() => props.onHide()}>
            Cancel
          </Button>
          <Button className="efi-primary-btn" onClick={() => props.onHide()}>
            Send Invite
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default addTeamMember;