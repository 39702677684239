import React, { useEffect, useState } from "react";
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  beneficiaryListStart,
  createTransactionStart,
} from "../../store/slices/BeneficiariesSlice";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";

const SingleBeneficiary = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("quote.quote_transfer");
  const formRef = React.useRef();
  const beneficiaryList = useSelector(
    (state) => state.beneficiaries.beneficiaryList
  );
  const [beneficiary, setBeneficiary] = useState([]);
  const createTransaction = useSelector(
    (state) => state.beneficiaries.createTransaction
  );
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dee2e6!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isSelected ? "#f7f7f7" : "#fff",
    //     color: "#000",
    //     "&:hover": {
    //       backgroundColor: "#f7f7f7",
    //       color: "#000",
    //     },
    //   };
    // },
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#1b3d86" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#1b3d86",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    beneficiary_account_id: Yup.number()
      .required(t("required"))
      .min(1, t("amount.minimum")),
  });

  useEffect(() => {
    props.transferData &&
      !props.transferData.beneficiary_account_id &&
      dispatch(
        beneficiaryListStart({
          receiving_currency: props.transferData.receiving_currency,
          recipient_type: props.transferData.recipient_type,
          recipient_country: props.transferData.recipient_country,
        })
      );
  }, [props]);

  useEffect(() => {
    if (
      // !skipRender &&
      !beneficiaryList.loading &&
      Object.keys(beneficiaryList.data).length > 0
    ) {
      // beneficiaryList.data.total_beneficiary_accounts > 0 &&
        setBeneficiary(
          beneficiaryList.data.beneficiary_accounts.map((data) => ({
            label: `${data.account_number}-${data.receiving_currency} `,
            value: data.id,
          }))
        );
    }
    // setSkipRender(false);
  }, [beneficiaryList]);

  useEffect(() => {
    if (props.transferData.beneficiary_account_id) {
      let data = beneficiary.find(
        (item) => item.value == props.transferData.beneficiary_account_id
      );
      data && setSelectedBeneficiary(data);
      formRef.current?.setFieldValue(
        "beneficiary_account_id",
        props.transferData.beneficiary_account_id
      );
    }
  }, [beneficiary, props.transferData]);

  const handleSubmit = (values) => {
    props.setTransferData({
      ...props.transferData,
      ...values,
    });
    props.setStep(props.step + 1);
  };

  return (
    <>
      <div className="single-transaction-beneficiary">
        <div className="single-transaction-back">
          {/* <Button
            className="efi-overlay-btn"
            onClick={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default action when pressing Enter
                return;
              }
              props.setStep(props.step - 1);
            }}
            // disabled={updateAddress.buttonDisable}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            disabled={createTransaction.buttonDisable}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 24 24"
            >
              <path
                fill="#1b3d86"
                d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Back</span>
          </Button> */}
        </div>
        <div className="single-transaction-box-avater">
          <Image
            className="trans-beneficiary-avater"
            src={window.location.origin + "/img/beneficiary-select.svg"}
            type="image/png"
          />
        </div>
        <div className="single-transaction-box-field">
          <h4>Select Beneficiary</h4>
          <p>
            Beneficiaries can be individuals, businesses, or charitable
            organizations. The account that receives the funds is called the
            beneficiary account
          </p>
          {beneficiaryList.loading ? (
            <Skeleton height={45} width={"100%"} />
          ) : Object.keys(beneficiaryList.data).length > 0 ? (
            <Formik
              initialValues={{
                beneficiary_account_id: selectedBeneficiary.value || "",
                quote_id: props.transferData.quote.id,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ setFieldValue, values, touched, errors, resetForm }) => (
                <FORM className="efi-bank-default-form w-100">
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("beneficiary.name")} *
                        </Form.Label>
                        <Select
                          options={beneficiary}
                          styles={customStyles}
                          value={selectedBeneficiary}
                          classNamePrefix="react-select"
                          onChange={(data) => {
                            data !== null && setSelectedBeneficiary(data);
                            setFieldValue("beneficiary_account_id", data.value);
                          }}
                          placeholder={t("beneficiary.placeholder")}
                          isDisabled={beneficiaryList.buttonDisable}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="beneficiary_account_id"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="onboarding-auth-footer mt-3">
                    <Button
                      className="efi-primary-btn w-100"
                      type="submit"
                      disabled={createTransaction.buttonDisable}
                    >
                      {createTransaction.buttonDisable ? (
                        <ButtonLoader />
                      ) : (
                        "Confirm Beneficiary"
                      )}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
          ) : (
            <div className="single-transaction-box-action margin-top-sm">
              <Button
                type="button"
                className="efi-primary-btn w-100 mt-3"
                disabled={beneficiaryList.buttonDisable}
                onClick={() =>
                  dispatch(
                    beneficiaryListStart({
                      receiving_currency: props.transferData.receiving_currency,
                      recipient_type: props.transferData.recipient_type,
                    })
                  )
                }
              >
                Retry
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleBeneficiary;
