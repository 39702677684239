import React, { useState } from "react";
import { Button, Table } from 'react-bootstrap';
import AddNewMember from './AddNewMember'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select';
import "./userAccess.css"


const TeamMenberListTable = () => {
  const [addNewMember, setAddNewMember] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "42px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
  }),
  };

  const options = [
    { value: "option 1", label: "Option 1" },
    { value: "option 2", label: "Option 2" },
    { value: "option 3", label: "Option 3" },
    { value: "option 4", label: "Option 4" },
  ]

  return (
    <>
      <div className="payment-table-header">
        <h3>Team Member</h3>
        <div className="payment-table-header-right">
          <Select
            styles={customStyles}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
          />
          <Button className="efi-export-btn" onClick={() => setAddNewMember(true)}>
            Add New Member
          </Button>
          <AddNewMember show={addNewMember} onHide={() => setAddNewMember(false)} />
        </div>
      </div >
      <Table className='common-table' responsive>
        <thead className='common-table-header'>
          <tr>
            <th>#ID</th>
            <th>User Name</th>
            <th>Created Date</th>
            <th>Roles</th>
            <th>Status</th>
            <th>Created By</th>
          </tr>
        </thead>
        <tbody className='common-table-body'>
          <tr>
            <td>#4589445</td>
            <td>Mathew</td>
            <td>10 July 2024</td>
            <td>User</td>
            <td><div className='green-badge'>Active</div></td>
            <td>John Doe</td>
          </tr>
          <tr>
            <td>#4589445</td>
            <td>Mathew</td>
            <td>10 July 2024</td>
            <td>User</td>
            <td><div className='orange-badge'>Inactive</div></td>
            <td>John Doe</td>
          </tr>
          <tr>
            <td>#4589445</td>
            <td>Mathew</td>
            <td>10 July 2024</td>
            <td>User</td>
            <td><div className='orange-badge'>Active</div></td>
            <td>John Doe</td>
          </tr>
          <tr>
            <td>#4589445</td>
            <td>Mathew</td>
            <td>10 July 2024</td>
            <td>User</td>
            <td><div className='green-badge'>Active</div></td>
            <td>John Doe</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
};
export default TeamMenberListTable;