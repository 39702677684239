import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { countries } from "countries-list";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { updateProfileStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";

const ProfileEditDetails = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("profile.profile_edit");
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const profile = useSelector((state) => state.admin.profile);
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
  }),
  };

  const [selectedTitle, setSelectedTitle] = useState(null);

  const title_options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("required")),
    first_name: Yup.string()
      .required(t("required")).max(20, t("invalid"))
      .min(4, t("required_note"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    middle_name: Yup.string()
      .max(20, t("invalid"))
      .min(2, t("required_notes")),
    last_name: Yup.string()
      .required(t("required")).max(20, t("invalid"))
      .min(1, t("min_invalid"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    gender: Yup.string().required(t("required")),
    nationality: Yup.string().required(t("required")),
  });

  const countryOptions = Object.keys(countries)
    .filter(
      (country) =>
        ![
          "AF",
          "BY",
          "CF",
          "CN",
          "CU",
          "CD",
          "ET",
          "IN",
          "IR",
          "IQ",
          "LY",
          "ML",
          "NI",
          "KP",
          "SO",
          "SS",
          "SD",
          "SY",
          "US",
          "VE",
          "YE",
          "ZW",
        ].includes(country)
    )
    .map((alpha2) => ({
      value: alpha2,
      label: countries[alpha2].name,
    }));

  const handleSubmit = (values) => {
    dispatch(
      updateProfileStart({
        ...values,
        email: profile.data.email,
        mobile_country_code: profile.data.mobile_country_code,
        mobile: profile.data.mobile,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateProfile.loading &&
      Object.keys(updateProfile.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [updateProfile]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="proxy-card-input-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-card-title">
            <h3 className="change-password-heading">{t("edit_profile")}</h3>
          </div>
          <Formik
            initialValues={{
              title: profile.data.title,
              first_name: profile.data.first_name,
              middle_name: profile.data.middle_name,
              last_name: profile.data.last_name,
              gender: profile.data.gender,
              nationality: profile.data.nationality,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form w-100">
                <Row>
                  <div className="mb-3">
                    <Row className="g-1">
                      <Col lg={3}>
                        <Form.Label className="default-form-label w-100">
                          {t("title")} <span>*</span>
                        </Form.Label>
                        <Select
                          styles={customStyles}
                          value={title_options.find(
                            (item) => item.value == values.title
                          )}
                          onChange={(option) => {
                            setSelectedTitle(option);
                            setFieldValue("title", option.value);
                          }}
                          options={title_options}
                          name="title"
                          placeholder={<>Title</>}
                          isSearchable={false}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="title"
                          className="errorMsg"
                        />
                      </Col>
                      <Col lg={9}>
                        <Form.Group >
                          <Form.Label className="default-form-label w-100">
                            {t("first_name")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            name="first_name"
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                            }}
                            placeholder="First Name"
                            aria-label="text"
                            aria-describedby="text-addon"
                            maxLength={16}
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="first_name"
                          className="errorMsg"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-3">
                    <Row className="g-1">
                      <Col lg={6}>
                        <Form.Group >
                          <Form.Label className="default-form-label w-100">
                            {t("middle_name")}
                          </Form.Label>

                          <Field
                            className="form-control"
                            name="middle_name"
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                            }}
                            placeholder="Middle Name"
                            aria-label="text"
                            aria-describedby="text-addon"
                            maxLength={16}
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="middle_name"
                          className="errorMsg"
                        />
                      </Col>
                      <Col lg={6}>
                        <Form.Group >
                          <Form.Label className="default-form-label w-100">
                            {t("last_name")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            name="last_name"
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                            }}
                            placeholder="Last Name"
                            aria-label="text"
                            aria-describedby="text-addon"
                            maxLength={16}
                          />
                        </Form.Group>
                        <ErrorMessage
                          component={"div"}
                          name="last_name"
                          className="errorMsg"
                        />
                      </Col>
                    </Row>
                  </div>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("nationality")} <span>*</span>
                      </Form.Label>
                      <Select
                        options={countryOptions}
                        styles={customStyles}
                        classNamePrefix="react-select"
                        onChange={(selectedOption) =>
                          setFieldValue("nationality", selectedOption.value)
                        }
                        value={countryOptions.find(
                          (option) => option.value === values.nationality
                        )}
                        placeholder="Select Nationality"
                      />
                    </Form.Group>
                    <ErrorMessage
                      component={"div"}
                      name="nationality"
                      className="errorMsg"
                    />
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("gender")} <span>*</span>
                      </Form.Label>
                      <div className="gender-check-box">
                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            name="flexRadioDefault"
                            id="male"
                            checked={values.gender == "M"}
                            onChange={() => setFieldValue("gender", "M")}
                          />
                          <div className="state p-primary-o">
                            <label>{t("male")}</label>
                          </div>
                        </div>
                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            name="flexRadioDefault"
                            id="female"
                            checked={values.gender == "F"}
                            onChange={() => setFieldValue("gender", "F")}
                          />
                          <div className="state p-primary-o">
                            <label>{t("female")}</label>
                          </div>
                        </div>
                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            name="flexRadioDefault"
                            id="other"
                            checked={values.gender == "O"}
                            onChange={() => setFieldValue("gender", "O")}
                          />
                          <div className="state p-primary-o">
                            <label>{t("other")}</label>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <ErrorMessage
                      component={"div"}
                      name="gender"
                      className="errorMsg"
                    />
                  </Col>
                </Row>
                <div className="onboarding-auth-footer mt-3">
                  <div className="space-tow w-100">
                    <Button
                      className="efi-primary-btn w-100"
                      type="submit"
                      
                      disabled={updateProfile.buttonDisable}
                    // onClick={() => props.setStep(3)}
                    >
                      {updateProfile.buttonDisable ? (
                        <ButtonLoader varient="black" />
                      ) : (
                        t("continue")
                      )}
                    </Button>
                  </div>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileEditDetails;
