import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Scatter,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const LineGraph = (props) => {

  const dashboardChatList = useSelector(
    (state) => state.dashboard.dashboardChatList
  );

  const data = props.transactionData.model_data.map((transaction, index) => ({
    name: props.transactionData.days[index],
    Transactions: transaction,
    // Payout: props.payoutData.model_data[index],
  }));

  return (
    <>
      <div className="line-graph">
        <div className="line-graph-body">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis width={40}/>
              <Tooltip />
              <Area
                type="monotone"
                dataKey="Transactions"
                stroke="#1b3d86"
                fill="#F0F9FF"
              />
              <Scatter dataKey="cnt" fill="red" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default LineGraph;
